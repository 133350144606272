/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// Import global styles
import "./src/styles/global.css";

// Google Analytics tracking for Gatsby client-side navigation
export const onRouteUpdate = ({ location }) => {
  if (typeof window.gtag === "function") {
    window.gtag("config", "G-8SMKS3L9D6", {
      page_path: location.pathname,
    });
  }
};